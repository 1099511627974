import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>refresh.so</h1>
            <h4>August 2020 - Present</h4>
            <p>
                Building a way to refresh yourself on your meeting before you have them. Development in progress now, join the waitlist at <a rel="noopener noreferrer"  href="https://refresh.so/" target="_blank">refresh.so</a>.
            </p>
        </ProjectPage>
    </div>)
}

export default IndexPage
